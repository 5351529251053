<template>
  <div>
    <b-card class="mb-4">
      <div class="flex-responsive mb-3">
        <div class="d-flex flex-column mr-5">
          <small class="font-weight-bold text-valorem-grafite">
            {{ $t("ESTABELECIMENTOS.LIMITE_CREDITO_MAXIMO") }}
          </small>
          <h5 class="text-valorem-azul-naval">{{ valorLimiteCreditoMaximo }}</h5>
        </div>
        <div class="d-flex flex-column mr-5">
          <small class="font-weight-bold text-valorem-grafite">
            {{ $t("ESTABELECIMENTOS.LIMITE_CREDITO_ATIVO") }}
          </small>
          <h5 class="text-valorem-azul-naval">{{ valorLimiteCreditoAtivo }}</h5>
        </div>
        <div class="d-flex flex-column mr-5">
          <small class="font-weight-bold text-valorem-grafite">
            {{ $t("ESTABELECIMENTOS.LIMITE_CREDITO_UTILIZADO") }}
          </small>
          <h5 class="text-valorem-vermelho">{{ valorLimiteCreditoUtilizado }}</h5>
        </div>
        <div class="d-flex flex-column">
          <small class="font-weight-bold text-valorem-grafite">
            {{ $t("ESTABELECIMENTOS.LIMITE_CREDITO_DISPONIVEL") }}
          </small>
          <h5 class="text-valorem-ciano">{{ valorLimiteCreditoDisponivel }}</h5>
        </div>
      </div>

      <div class="flex-between-responsive fill mb-3">
        <input-checkbox
          id="input-liberar-antecipacao"
          switch
          :text="$t('ESTABELECIMENTOS.LIBERAR_ANTECIPACAO')"
          v-model="form.liberarAntecipacaoRecorrencia"
        />
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <input-date
                  id="input-data-inicio-antecipacao"
                  class="col-12 col-md-6"
                  :label="$t('ESTABELECIMENTOS.DATA_ANTECIPACACAO')"
                  v-model="form.dataInicioAntecipacao"
                  ref="dataAntecipacacao"
                  :required="form.liberarAntecipacaoRecorrencia"
                  :disabled="!form.liberarAntecipacaoRecorrencia"
                />
              </div>
            </div>
            <input-currency
              id="input-faturamento-mensal"
              class="col-12 col-md-6"
              :label="$t('ESTABELECIMENTOS.FATURAMENTO_MENSAL')"
              v-model="form.faturamentoMensal"
              ref="faturamentoMensal"
              :required="form.liberarAntecipacaoRecorrencia"
              :disabled="!form.liberarAntecipacaoRecorrencia"
            />

            <input-text
              id="input-percentual-antecipacao"
              class="col-12 col-md-6"
              :label="$t('ESTABELECIMENTOS.PERCENTUAL_ANTECIPACADO')"
              v-model="form.percentualAntecipacao"
              ref="percentualAntecipacao"
              type="number"
              :max="100"
              :required="form.liberarAntecipacaoRecorrencia"
              :disabled="!form.liberarAntecipacaoRecorrencia"
            />

            <input-currency
              id="input-limite-maximo-inicial"
              class="col-12 col-md-6"
              :label="$t('ESTABELECIMENTOS.LIMITE_MAX')"
              v-model="form.limiteMaximoInicial"
              ref="limiteMaximoInicial"
              :required="form.liberarAntecipacaoRecorrencia"
              :disabled="!form.liberarAntecipacaoRecorrencia"
            />

            <input-text
              id="input-validade-limite-inicial-dias"
              class="col-12 col-md-6"
              :label="$t('ESTABELECIMENTOS.VALIDADE_INICIAL')"
              v-model="form.validadeLimiteInicialDias"
              ref="validadeLimiteInicialDias"
              type="number"
              :required="form.liberarAntecipacaoRecorrencia"
              :disabled="!form.liberarAntecipacaoRecorrencia"
            />

            <input-text
              id="input-taxa-credito"
              class="col-12 col-md-6"
              :label="$t('ESTABELECIMENTOS.TAXA_CREDITO')"
              type="number"
              :min="0"
              :max="100"
              v-model="form.taxaCreditoAVista"
              ref="taxaCreditoAVista"
              :required="form.liberarAntecipacaoRecorrencia"
              :disabled="!form.liberarAntecipacaoRecorrencia"
            />

            <input-currency
              id="input-limite-inadiplencia"
              class="col-12 col-md-6"
              :label="$t('ESTABELECIMENTOS.LIMITE_INADIMPLECIA')"
              v-model="form.limiteInadimplencia"
              ref="validadeLimiteInadimplenciaDias"
              :required="form.liberarAntecipacaoRecorrencia"
              :disabled="!form.liberarAntecipacaoRecorrencia"
            />
          </div>
        </div>
      </div>

      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            id="btn-cancelar-antecipacao"
            variant="outline-primary"
            @click="cancelar"
          >
            {{ $t("GERAL.CANCELAR") }}
          </b-button>
          <b-button
            id="btn-salvar"
            class="ml-2"
            variant="primary"
            @click="salvar"
            v-if="form.id ? permissao.editar : permissao.criar"
          >
            {{ $t("GERAL.SALVAR") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
import helpers from "../../common/utils/helpers";
import {
  InputCurrency,
  InputText,
  InputCheckbox,
  InputDate,
} from "../../components/inputs";

export default {
  name: "Antecipacao",
  components: {
    InputText,
    InputCheckbox,
    InputDate,
    InputCurrency,
  },
  props: {
    form: {
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    cancelar: function () {
      this.$router.push({ name: "estabelecimentos" });
    },
    salvar: function () {
      this.$emit("salvar");
    },
  },
  computed: {
    permissao: function () {
      return helpers.validarAcesso("Estabelecimento");
    },
    valorLimiteCreditoMaximo: function () {
      return helpers.formatarValor(
        helpers.removerValor(`${this.form.faturamentoMensal}`) *
          (this.form.percentualAntecipacao / 100)
      );
    },
    valorLimiteCreditoAtivo: function () {
      let diferenca = moment().diff(
        moment(this.form.dataInicioAntecipacao).add(
          this.form.validadeLimiteInicialDias,
          "days"
        ),
        "days"
      );

      if (diferenca <= 0)
        return helpers.formatarValor(
          helpers.removerValor(`${this.form.limiteMaximoInicial}`)
        );
      return this.valorLimiteCreditoMaximo;
    },
    valorLimiteCreditoDisponivel: function () {
      let calculo =
        helpers.removerValor(this.valorLimiteCreditoAtivo) -
        this.form.valorLimiteCreditoUtilizado;
      return helpers.formatarValor(calculo);
    },
    valorLimiteCreditoUtilizado: function () {
      return helpers.formatarValor(this.form.valorLimiteCreditoUtilizado);
    },
  },
  watch: {
    "form.liberarAntecipacaoRecorrencia": function () {
      if (!this.form.liberarAntecipacaoRecorrencia) {
        this.form.dataInicioAntecipacao = null;
        this.form.faturamentoMensal = 0;
        this.form.percentualAntecipacao = 0;
        this.form.limiteMaximoInicial = 0;
        this.form.validadeLimiteInicialDias = 0;
        this.form.taxaCreditoAVista = 0;
        this.form.validadeLimiteInadimplenciaDias = 0;
      }
    },
  },
};
</script>
