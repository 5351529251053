<template>
  <valorem-modal
    id="modal-usuario"
    ref="modal"
    :titulo-modal="$t('ESTABELECIMENTOS.NOVO_USUARIO')"
    @ok="salvar"
    @cancelar="fecharModal"
  >
    <div class="flex-between-responsive fill align-items-start">
      <div class="flex-fill">
        <input-text
          id="input-filtro-disponiveis"
          no-label
          :placeholder="$t('USUARIOS.FILTRO')"
          v-model="usuariosDisponiveis.filtro"
          @blur="filtrarUsuariosDisponiveis"
        />
        <valorem-tabela
          id="tabela-disponiveis"
          v-model="usuariosDisponiveisFiltrados"
          :colunas="usuariosDisponiveis.tabela.colunas"
          :quantidade-itens="usuariosDisponiveis.tabela.quantidadeItens"
          ref="tabela-usuarios-disponiveis"
          backend-paginado
          @paginar="listarUsuariosDisponiveis"
        >
          <template #cell(perfil)="dados">
            <b-badge :variant="retornarPerfil(dados.value).variante">
              {{ retornarPerfil(dados.value).text }}
            </b-badge>
          </template>
        </valorem-tabela>
      </div>
      <div class="align-self-center">
        <div class="row mx-3">
          <b-button
            id="btn-adicionar"
            class="col-6 col-md-12 mb-2"
            @click="adicionarUsuarios"
            :disabled="!usuariosDisponiveisSelecionados.length"
            variant="outline-valorem-ciano"
          >
            <div class="d-flex justify-content-center">
              <feather type="chevron-right" />
            </div>
          </b-button>
          <b-button
            id="btn-remover"
            class="col-6 col-md-12 mb-2"
            @click="removerUsuarios"
            :disabled="!usuariosAlocadosSelecionados.length"
            variant="outline-valorem-ciano"
          >
            <div class="d-flex justify-content-center">
              <feather type="chevron-left" />
            </div>
          </b-button>
        </div>
      </div>
      <div class="flex-fill align-items-top">
        <input-text
          id="input-filtro-alocados"
          no-label
          :placeholder="$t('ESTABELECIMENTOS.USUARIOS.FILTRO')"
          v-model="usuariosAlocados.filtro"
        />
        <valorem-tabela
          id="tabela-alocados"
          v-model="usuariosAlocadosFiltrados"
          :colunas="usuariosDisponiveis.tabela.colunas"
          ref="tabela-usuarios-alocados"
        >
          <template #cell(perfil)="dados">
            <b-badge :variant="retornarPerfil(dados.value).variante">
              {{ retornarPerfil(dados.value).text }}
            </b-badge>
          </template>
        </valorem-tabela>
      </div>
    </div>
  </valorem-modal>
</template>
<script>
import { InputText } from "@/components/inputs";
import UsuarioService from "@/common/services/usuario/usuario.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import helpers from "../../../common/utils/helpers";
import cloneDeep from "lodash.clonedeep";
export default {
  name: "ModalUsuario",
  components: {
    InputText,
  },
  data() {
    return {
      usuariosDisponiveis: {
        tabela: {
          colunas: [
            { key: "seletor", label: "" },
            {
              key: "userName",
              label: this.$t("USUARIOS.TABELA.USUARIO"),
              sortable: true,
            },
            { key: "nome", label: this.$t("USUARIOS.TABELA.NOME"), sortable: true },
            { key: "perfil", label: this.$t("USUARIOS.TABELA.PERFIL"), sortable: true },
          ],
          dados: [],
          quantidadeItens: 0,
          paginaAtual: 0,
          porPagina: 0,
        },
        filtro: "",
        paginaAtual: 0,
        porPagina: 0,
      },
      usuariosAlocados: {
        tabela: {
          dados: [],
        },
        filtro: "",
      },
    };
  },
  props: {
    form: {
      required: true,
    },
  },
  mounted() {},
  methods: {
    abrirModal: function () {
      this.listarUsuariosDisponiveis();
      this.usuariosAlocados.tabela.dados = cloneDeep(this.form);
      this.$refs.modal.abrirModal();
    },
    fecharModal: function () {
      this.$emit("cancelar");
      this.$refs.modal.fecharModal();
    },

    filtrarUsuariosDisponiveis: function () {
      this.$refs["tabela-usuarios-disponiveis"].limpar();
      this.listarUsuariosDisponiveis();
    },
    listarUsuariosDisponiveis: function (paginaAtual = 0, porPagina = 10) {
      this.$store.dispatch(START_LOADING);
      UsuarioService.listar(this.usuariosDisponiveis.filtro, paginaAtual, porPagina, true)
        .then((res) => {
          this.usuariosDisponiveis.tabela.dados = res.data.data.itens;
          this.usuariosDisponiveis.paginaAtual = paginaAtual;
          this.usuariosDisponiveis.porPagina = porPagina;
          this.usuariosDisponiveis.tabela.quantidadeItens =
            res.data.data.paginacao.totalDeElementos;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    retornarPerfil: function (perfil) {
      return helpers.getEnum(perfil);
    },
    removerUsuarios: function () {
      this.usuariosAlocadosSelecionados.forEach((element) => {
        this.usuariosAlocados.tabela.dados.splice(
          this.usuariosAlocados.tabela.dados.indexOf(element),
          1
        );
      });
    },
    adicionarUsuarios: function () {
      this.usuariosAlocados.tabela.dados.push(
        ...this.usuariosDisponiveisSelecionados.map((el) => {
          el.selecionado = false;
          return el;
        })
      );
      this.$refs["tabela-usuarios-disponiveis"].limparSelecao();
    },

    salvar: function () {
      this.confirmar(
        this.$t("GERAL.CONFIRMAR"),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        if (!this.validarFormulario()) return false;

        this.$emit(
          "salvar",
          this.usuariosAlocados.tabela.dados.map((el) => {
            el.selecionado = false;
            return el;
          })
        );
      });
    },
  },
  computed: {
    usuariosAlocadosFiltrados: {
      set(dados) {
        this.usuariosAlocados.tabela.dados = dados;
      },
      get() {
        return this.usuariosAlocados.tabela.dados.filter((el) => {
          return el.nome
            .toLowerCase()
            .includes(this.usuariosAlocados.filtro.toLowerCase());
        });
      },
    },
    usuariosDisponiveisFiltrados: {
      set(dados) {
        this.usuariosDisponiveis.tabela.dados = dados;
      },
      get() {
        return this.usuariosDisponiveis.tabela.dados.filter((el) => {
          return !this.usuariosAlocados.tabela.dados.find((el2) => el.id == el2.id);
        });
      },
    },
    usuariosAlocadosSelecionados: function () {
      return this.usuariosAlocados.tabela.dados.filter((el) => {
        return el.selecionado;
      });
    },
    usuariosDisponiveisSelecionados: function () {
      return this.usuariosDisponiveis.tabela.dados.filter((el) => {
        return el.selecionado;
      });
    },
  },
  watch: {
    form: {
      handler(value) {
        this.usuariosAlocados.tabela.dados = cloneDeep(value);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
