<template>
  <b-card no-body class="mb-4 p-3">
    <div>
      <h4 class="card-title">{{ quantidadeItens }} registros</h4>
    </div>
    <div
      class="filtro-valorem d-flex align-items-center justify-content-end mb-2"
    >
      <b-button
        id="btn-nova-conta"
        @click="abrirModal"
        v-if="permissao"
        variant="primary"
      >
        {{ $t("ESTABELECIMENTOS.NOVO_CONTA") }}
      </b-button>
    </div>

    <div class="d-flex align-items-center mb-2">
      <b-button
        id="btn-editar-conta"
        @click="editar(null)"
        v-if="dadosSelecionados.length == 1 && permissao"
        class="mr-2"
        variant="outline-primary"
      >
        {{ $t("GERAL.EDITAR") }}
      </b-button>
      <b-button
        id="btn-alterar-situacao-conta"
        @click="ativarInativar(botaoAtivarInativar)"
        v-if="
          dadosSelecionados.length == 1 &&
          !dadosSelecionados[0].ativo &&
          permissao
        "
        :disabled="verificarId()"
        variant="outline-primary"
      >
        {{
          `${!botaoAtivarInativar ? $t("GERAL.ATIVAR") : $t("GERAL.INATIVAR")}`
        }}
      </b-button>
      <b-button
        @click="confirmGerarToken()"
        v-if="dadosSelecionados.length == 1"
        :disabled="verificarId()"
        variant="outline-primary"
      >
        {{ $t("ESTABELECIMENTOS.GERAR_TOKEN") }}
      </b-button>
    </div>
    <valorem-tabela
      id="tabela-contas"
      sem-selecionar-todos
      :metodo-validacao-selecionar="metodoValidacaoSelecionar"
      v-model="form.contasBancarias"
      :colunas="tabela.colunas"
      ref="tabela"
    >
      <template #cell(ativo)="dados">
        <span
          :class="`text-${
            retornarVariante(dados.value).variante
          } text-capitalize`"
        >
          {{ retornarVariante(dados.value).text }}
        </span>
      </template>
      <template #cell(token)="dados">
        <span
          :class="`text-${
            retornarVarianteToken(dados.value).variante
          } text-capitalize`"
        >
          {{ retornarVarianteToken(dados.value).text }}
        </span>
      </template>
    </valorem-tabela>

    <b-row class="mt-3">
      <b-col cols="12" class="text-right mt-3">
        <b-button
          id="btn-cancelar-conta"
          class="mx-3"
          variant="outline-primary"
          @click="cancelar"
        >
          {{ $t("GERAL.CANCELAR") }}
        </b-button>
        <b-button id="btn-continuar-conta" variant="primary" @click="continuar">
          {{ $t("GERAL.CONTINUAR") }}
        </b-button>
      </b-col>
    </b-row>
    <modal-conta-bancaria
      :modalData="dadosSelecionados"
      ref="modalContaBancaria"
      :form="formContaBancaria"
      @salvar="salvar"
      @cancelar="limparFormulario"
    />
    <modal-token ref="modaToken" :token="token" />
  </b-card>
</template>

<script>
import ModalContaBancaria from "./components/ModalContaBancaria";
import helpers from "@/common/utils/helpers";
import cloneDeep from "lodash.clonedeep";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import EstabelecimentoService from "@/common/services/estabelecimentos/estabelecimentos.service";
import ModalToken from "./components/ModalToken";

export default {
  name: "ContaBancaria",
  props: {
    form: {
      required: true,
    },
  },
  components: {
    ModalContaBancaria,
    ModalToken,
  },
  data() {
    return {
      token: null,
      filtro: "",
      formContaBancaria: {
        favorecido: null,
        cpfCnpj: null,
        banco: null,
        tipoConta: "ContaPoupanca",
        agencia: null,
        conta: null,
        ativo: true,
        digitoConta: null,
        digitoAgencia: null,
        convenio: null,
      },
      formAuxiliar: null,
      tabela: {
        colunas: [
          { key: "seletor", label: "" },
          {
            key: "favorecido",
            label: this.$t("ESTABELECIMENTOS.CONTA_BANCARIA.FAVORECIDO"),
          },
          {
            key: "cpfCnpj",
            label: this.$t("ESTABELECIMENTOS.CONTA_BANCARIA.CPF_CNPJ"),
          },
          {
            key: "banco",
            label: this.$t("ESTABELECIMENTOS.CONTA_BANCARIA.BANCO"),
          },
          {
            key: "tipoConta",
            label: this.$t("ESTABELECIMENTOS.CONTA_BANCARIA.TIPO_CONTA"),
            formatter: (v) => {
              if (v) return helpers.getEnum(v).text;
            },
          },
          {
            key: "agencia",
            label: this.$t("ESTABELECIMENTOS.CONTA_BANCARIA.AGENCIA"),
          },
          {
            key: "digitoAgencia",
            label: this.$t("ESTABELECIMENTOS.CONTA_BANCARIA.DIGITOVERIFICADOR"),
          },
          {
            key: "conta",
            label: this.$t("ESTABELECIMENTOS.CONTA_BANCARIA.CONTA"),
          },
          {
            key: "digitoConta",
            label: this.$t("ESTABELECIMENTOS.CONTA_BANCARIA.DIGITOVERIFICADOR"),
          },
          { key: "ativo", label: this.$t("GERAL.STATUS") },
          {
            key: "token",
            label: this.$t("ESTABELECIMENTOS.CONTA_BANCARIA.TOKEN"),
          },
        ],
      },
      botaoAtivarInativar: null,
    };
  },
  computed: {
    quantidadeItens: function () {
      return this.form.contasBancarias.length;
    },
    dadosSelecionados: function () {
      return this.form.contasBancarias.filter((el) => {
        return el.selecionado;
      });
    },
    permissao: function () {
      return this.form.id
        ? helpers.validarAcesso("Estabelecimento").criar
        : helpers.validarAcesso("Estabelecimento").editar;
    },
  },
  methods: {
    verificarId() {
      return false;
    },
    continuar: function () {
      this.$emit("submitted");
    },
    cancelar: function () {
      this.$router.push({ name: "estabelecimentos" });
    },
    salvar: function () {
      this.formContaBancaria.selecionado = false;
      this.formContaBancaria.agencia = `${this.formContaBancaria.agencia}`;
      this.formContaBancaria.conta = `${this.formContaBancaria.conta}`;
      this.formContaBancaria.digitoConta = `${this.formContaBancaria.digitoConta}`;
      this.formContaBancaria.digitoAgencia =
        this.formContaBancaria.digitoAgencia == undefined
          ? null
          : `${this.formContaBancaria.digitoAgencia}`;
      this.formContaBancaria.convenio = `${this.formContaBancaria.convenio}`;
      if (!this.formContaBancaria.id && !this.formContaBancaria.codigo) {
        this.formContaBancaria.codigo = Math.random();
        this.formContaBancaria.ativo = this.form.contasBancarias.length == 0;
        this.form.contasBancarias.push(this.formContaBancaria);
      } else {
        this.form.contasBancarias[this.formAuxiliar] = this.formContaBancaria;
        this.$refs.tabela.atualizarValor(this.form.contasBancarias);
      }
      this.$refs.modalContaBancaria.fecharModal();
    },
    limparFormulario: function () {
      this.formContaBancaria = {
        favorecido: null,
        cpfCnpj: null,
        banco: null,
        tipoConta: "ContaPoupanca",
        agencia: null,
        conta: null,
        ativo: true,
      };
    },
    editar: function () {
      this.formContaBancaria = cloneDeep(this.dadosSelecionados[0]);
      this.formAuxiliar = this.form.contasBancarias.findIndex(
        (el) =>
          el.id == this.dadosSelecionados[0].id ||
          el.codigo == this.dadosSelecionados[0].codigo
      );
      this.$refs.modalContaBancaria.abrirModal();
    },
    metodoValidacaoSelecionar: function () {
      return true;
    },
    abrirModal() {
      this.$refs.modalContaBancaria.abrirModal();
    },
    ativarInativar: function (ativarInativar) {
      this.confirmar(
        this.$t(
          `ESTABELECIMENTOS.CONTA_BANCARIA.TITULO_${
            !ativarInativar ? "ATIVAR" : "INATIVAR"
          }`
        ),
        this.$t(
          `ESTABELECIMENTOS.CONTA_BANCARIA.CONFIRMACAO_${
            !ativarInativar ? "ATIVAR" : "INATIVAR"
          }`
        ),
        this.$t(
          `ESTABELECIMENTOS.CONTA_BANCARIA.BOTAO_${
            !ativarInativar ? "ATIVAR" : "INATIVAR"
          }`
        ),
        this.$t(`GERAL.CANCELAR`)
      ).then((confirmado) => {
        if (!confirmado) return;
        let id =
          this.dadosSelecionados[0].id || this.dadosSelecionados[0].codigo;
        this.form.contasBancarias = this.form.contasBancarias.map((el) => {
          if (id == el.id || id == el.codigo) {
            el.ativo = true;
          } else {
            el.ativo = false;
          }
          el.selecionado = false;
          return el;
        });
      });
    },
    confirmGerarToken() {
      this.confirmar(
        this.$t("ESTABELECIMENTOS.CONFIRMAR_GERAR_NOVO_TOKEN_TITULO"),
        this.$t("ESTABELECIMENTOS.CONFIRMAR_GERAR_NOVO_TOKEN_MENSAGEM")
      ).then((confirmado) => {
        if (confirmado) this.gerarToken();
      });
    },
    gerarToken() {
      this.$store.dispatch(START_LOADING);
      EstabelecimentoService.gerarToken(this.dadosSelecionados[0].id)
        .then((res) => {
          this.token = res.data.data.aggregateId;
          this.dadosSelecionados[0].token = this.token;

          this.$refs.modaToken.abrirModal();
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    retornarVarianteToken: function (token) {
      return helpers.Token.find((el) => el.value == token);
    },
  },
  mounted() {},
  watch: {
    dadosSelecionados: function () {
      if (this.dadosSelecionados.length == 1) {
        this.botaoAtivarInativar = this.dadosSelecionados[0].ativo;
      } else if (this.dadosSelecionados.length == 0) {
        this.botaoAtivarInativar = null;
      }
    },
  },
};
</script>
