<template>
  <div>
    <b-card class="mb-4">
      <div class="row">
        <input-mask
          id="input-cpf"
          class="col-12 col-md-2"
          :disabled="form.id"
          type="cpf_cnpj"
          ref="cpf-cnpj"
          v-model="form.cpfCnpj"
          :label="$t('ESTABELECIMENTOS.CPF_CNPJ')"
          :placeholder="$t('ESTABELECIMENTOS.CPF_CNPJ')"
          :mask="['###.###.###-##', '##.###.###/####-##']"
          required
        />
        <input-text
          id="input-razao"
          class="col-12 col-md"
          ref="razao-social"
          v-model="form.razaoSocial"
          :label="$t('ESTABELECIMENTOS.RAZAO_SOCIAL')"
          required
        />
        <input-text
          id="input-nome"
          class="col-12 col-md"
          ref="nome-fantasia"
          v-model="form.nomeFantasia"
          :label="$t('ESTABELECIMENTOS.NOME_FANTASIA')"
          required
        />
      </div>
      <div class="row">
        <input-autocomplete
          id="input-mcc"
          class="col-12 col-md-12"
          ref="mcc"
          :options="opcoes.mcc"
          v-model="form.cnaeMCC"
          :label="$t('ESTABELECIMENTOS.MCC')"
          required
        >
          <template #label>
            <div class="d-inline-flex align-items-center">
              <span class="mr-2">
                {{ $t("ESTABELECIMENTOS.MCC") }}
              </span>
              <feather
                type="alert-circle"
                size="14"
                v-b-tooltip.hover
                :title="mccSelecionado"
              ></feather>
            </div>
          </template>
        </input-autocomplete>
      </div>

      <div class="row">
        <input-text
          id="input-merchant"
          class="col-12 col-md-4"
          ref="merchant_id"
          v-model="form.eSitefMerchantId"
          :label="$t('ESTABELECIMENTOS.MERCHANT_ID')"
          readonly
        ></input-text>
        <input-text
          id="input-soft"
          class="col-12 col-md-4"
          ref="soft_descriptor"
          v-model="form.softDescriptor"
          :label="$t('ESTABELECIMENTOS.SOFT_DESCRIPTOR')"
          :max-length="30"
          required
        >
          <template #label>
            <div class="d-inline-flex align-items-center">
              <span class="mr-2">
                {{ $t("ESTABELECIMENTOS.SOFT_DESCRIPTOR") }}
              </span>
              <feather
                type="help-circle"
                size="14"
                v-b-tooltip.hover
                :title="$t('ESTABELECIMENTOS.SOFT_DESCRIPTOR_DESCRICAO')"
              ></feather>
            </div>
          </template>
        </input-text>
        <input-select
          id="input-enviar-link"
          class="col-12 col-md-4"
          :options="opcoes.numeroDeParcelas"
          v-model="form.numeroDeParcelas"
          :label="$t('ESTABELECIMENTOS.PARCELA_MAXIMA')"
        />
      </div>

      <div class="row">
        <input-autocomplete
          id="input-representante"
          class="col-12 col-md-4"
          :label="$t('ESTABELECIMENTOS.REPRESENTANTE_COMERCIAL')"
          :options="opcoes.representantes"
          v-model="form.representanteComercialId"
          ref="representante"
        />
        <input-select
          id="input-enviar-link"
          class="col-12 col-md-2"
          :options="opcoes.linkRecorrencia"
          v-model="form.enviaLinkRecorrencia"
          :label="$t('ESTABELECIMENTOS.LINK_RECORRENCIA')"
        />
        <input-select
          id="input-grupo-economico"
          class="col-12 col-md-6"
          ref="grupo_economico"
          :options="opcoes.grupoEconomico"
          v-model="form.grupoEconomicoId"
          :label="$t('ESTABELECIMENTOS.GRUPO_ECONOMICO')"
          required
        >
          <template #append>
            <b-button
              id="btn-novo-grupo"
              v-if="permissaoGrupoEconomico"
              @click="novoGrupoEconomico"
              class="ml-2"
              variant="outline-primary"
            >
              {{ $t("ESTABELECIMENTOS.NOVO_GRUPO") }}
            </b-button>
          </template>
        </input-select>
      </div>
      <b-row>
        <b-col cols="12" class="text-right mt-3">
          <b-button
            id="btn-cancelar-ficha"
            class="mx-3"
            variant="outline-primary"
            @click="cancelar"
          >
            {{ $t("GERAL.CANCELAR") }}
          </b-button>
          <b-button
            id="btn-continuar-ficha"
            variant="primary"
            @click="continuar"
          >
            {{ $t("GERAL.CONTINUAR") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <valorem-modal
      id="modal-grupo"
      ref="modal-grupo-economico"
      :titulo-modal="$t('GRUPO_ECONOMICO.TITULO_FORMULARIO_NOVO')"
      @ok="salvarGrupoEconomico"
    >
      <cadastro-grupo-economico modal ref="cadastro-grupo-economico" />
    </valorem-modal>
  </div>
</template>

<script>
import {
  InputText,
  InputSelect,
  InputMask,
  InputAutocomplete,
} from "@/components/inputs";
import GrupoEconomicoService from "@/common/services/grupo_economico/grupo_economico.service";
import CadastroGrupoEconomico from "@/views/grupo_economico/formulario";
import helpers from "../../common/utils/helpers";
import UsuarioService from "@/common/services/usuario/usuario.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
export default {
  name: "Ficha",
  props: {
    form: {
      required: true,
    },
  },
  components: {
    InputText,
    InputSelect,
    InputMask,
    InputAutocomplete,
    CadastroGrupoEconomico,
  },
  data() {
    return {
      opcoes: {
        mcc: [],
        grupoEconomico: [],
        representantes: [],
        linkRecorrencia: [
          { value: true, text: "Sim" },
          { value: false, text: "Não" },
        ],
        numeroDeParcelas: [
          { value: 12, text: "12 parcelas" },
          { value: 18, text: "18 parcelas" },
          { value: 21, text: "21 parcelas" },
        ],
      },
    };
  },
  mounted() {
    this.buscarGrupoEconomico();
    this.buscarSupervisores();
    this.buscarMCC();
  },
  methods: {
    buscarMCC: function () {
      this.opcoes.mcc = helpers.MCC.map((el) => {
        return {
          value: el.codigoCnae,
          text: `${el.codigoMCC} - ${el.descricaoMCC}(${el.codigoCnae})`,
          mcc: el.codigoMCC,
        };
      });
    },
    cancelar: function () {
      this.$router.push({ name: "estabelecimentos" });
    },
    continuar: function () {
      this.confirmar(
        this.$t("GERAL.CONFIRMAR"),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        if (!this.validarFormulario()) return false;

        this.$emit("submitted");
      });
    },
    buscarGrupoEconomico: function () {
      this.opcoes.grupoEconomico = [];
      GrupoEconomicoService.dropdown().then((res) => {
        this.opcoes.grupoEconomico = res.data.data.grupos.map((el) => {
          return {
            value: el.id,
            text: el.nome,
          };
        });
      });
    },
    novoGrupoEconomico: function () {
      this.$refs["modal-grupo-economico"].abrirModal();
    },
    salvarGrupoEconomico: function () {
      this.$refs["cadastro-grupo-economico"].salvar().then(() => {
        this.$refs["modal-grupo-economico"].fecharModal();
        this.buscarGrupoEconomico();
      });
    },
    buscarSupervisores: function () {
      this.$store.dispatch(START_LOADING);
      UsuarioService.buscarUsuariosPorPerfil({ perfil: ["Gerente"] })
        .then((res) => {
          this.opcoes.representantes = res.data.data.usuarios.map((el) => {
            return {
              value: el.id,
              text: el.nome,
            };
          });
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
  computed: {
    permissaoGrupoEconomico: function () {
      return helpers.validarAcesso("GrupoEconomico").criar;
    },
    mccSelecionado: function () {
      return this.opcoes.mcc.find((el) => el.value == this.form.cnaeMCC);
    },
  },
  watch: {
    "form.cnaeMCC": function () {
      this.form.MCC = this.opcoes.mcc.find(
        (el) => el.value == this.form.cnaeMCC
      ).mcc;
    },
  },
};
</script>
