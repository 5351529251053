<template>
  <valorem-modal
    id="modal-conta"
    ref="modal"
    :titulo-modal="$t('ESTABELECIMENTOS.CONTA_BANCARIA.TITULO_MODAL_NOVO')"
    @ok="salvar"
    @cancelar="fecharModal"
    tamanho="md"
  >
    <div class="row">
      <input-text
        id="input-favorecido"
        class="col-12 col-md-6"
        :label="$t('ESTABELECIMENTOS.CONTA_BANCARIA.FAVORECIDO')"
        v-model="form.favorecido"
        ref="favorecido"
        required
      />
      <input-mask
        id="input-cpf"
        class="col-12 col-md-6"
        :label="$t('ESTABELECIMENTOS.CONTA_BANCARIA.CPF_CNPJ')"
        v-model="form.cpfCnpj"
        type="cpf_cnpj"
        :mask="['###.###.###-##', '##.###.###/####-##']"
        ref="cpfCnpj"
        required
      />
      <input-text
        id="input-banco"
        class="col-12 col-md-6"
        :label="$t('ESTABELECIMENTOS.CONTA_BANCARIA.BANCO')"
        v-model="form.banco"
        ref="banco"
        required
      />
      <input-select
        id="input-tipo-conta"
        class="col-12 col-md-6"
        :label="$t('ESTABELECIMENTOS.CONTA_BANCARIA.TIPO_CONTA')"
        v-model="form.tipoConta"
        :options="opcoes.tipos"
        required
      />
      <div class="col-12 col-md-6">
        <div class="row">
          <input-text
            id="input-agencia"
            class="col-8"
            :label="$t('ESTABELECIMENTOS.CONTA_BANCARIA.AGENCIA')"
            v-model="form.agencia"
            :min-length="4"
            :max-length="4"
            ref="agencia"
            required
            somente-numeros
          />
          <input-text
            id="input-agencia-dv"
            class="col-4"
            :label="$t('ESTABELECIMENTOS.CONTA_BANCARIA.DIGITO')"
            v-model="form.digitoAgencia"
            :min-length="0"
            :max-length="1"
            ref="agencia-dv"
            somente-numeros
          />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row">
          <input-text
            id="input-conta"
            class="col-8"
            :label="$t('ESTABELECIMENTOS.CONTA_BANCARIA.CONTA')"
            v-model="form.conta"
            required
            :min-length="4"
            :max-length="11"
            ref="conta"
            somente-numeros
          />
          <input-text
            id="input-conta-dv"
            class="col-4"
            :label="$t('ESTABELECIMENTOS.CONTA_BANCARIA.DIGITO')"
            v-model="form.digitoConta"
            :min-length="1"
            :max-length="1"
            ref="conta-dv"
            required
            somente-numeros
          />
        </div>
      </div>

      <input-text
        id="input-convenio"
        class="col-3"
        :label="$t('ESTABELECIMENTOS.CONTA_BANCARIA.CONVENIO')"
        v-model="form.convenio"
        required
        :min-length="1"
        :max-length="7"
        ref="convenio"
        somente-numeros
      />
      <input-select
        id="input-proprietario-conta"
        class="col-12 col-md-5"
        :label="$t('ESTABELECIMENTOS.CONTA_BANCARIA.DONO_CONTA')"
        v-model="form.proprietarioConta"
        :options="opcoes.proprietarios"
        required
      />
      <input-text
        id="input-codigo-qprof"
        class="col-4"
        :label="$t('ESTABELECIMENTOS.CONTA_BANCARIA.CODIGO_QPROF')"
        v-model="form.codigoQprof"
        ref="codigo-qprof"
        disabled
      />
    </div>
  </valorem-modal>
</template>
<script>
import { InputText, InputMask, InputSelect } from "@/components/inputs";
import helpers from "../../../common/utils/helpers";

export default {
  name: "ModalContaBancaria",
  components: {
    InputText,
    InputSelect,
    InputMask,
  },
  data() {
    return {
      opcoes: {
        tipos: helpers.TipoContaBancaria,
        proprietarios: helpers.ProprietariosContaBancaria,
      },
    };
  },
  props: {
    form: {
      required: true,
    },
  },
  methods: {
    abrirModal: function () {
      this.$refs.modal.abrirModal();
    },
    fecharModal: function () {
      this.$emit("cancelar");
      this.$refs.modal.fecharModal();
    },
    salvar: function () {
      this.confirmar(
        this.$t("GERAL.CONFIRMAR"),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        if (!this.validarFormulario()) return false;

        this.$emit("salvar");
      });
    },
  },
};
</script>
