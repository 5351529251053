<template>
  <b-card no-body class="mb-4 p-3">
    <div>
      <h4 class="card-title">{{ quantidadeItens }} registros</h4>
    </div>
    <div class="filtro-valorem d-flex align-items-center justify-content-between mb-2">
      <input-text
        id="input-filtro"
        no-label
        :placeholder="$t('PLACEHOLDER.FILTRAR_CONTATO')"
        v-model="filtro"
        class="w-50"
      />
      <b-button
        id="btn-novo-contato"
        @click="abrirModal"
        v-if="permissao"
        variant="primary"
      >
        {{ $t("ESTABELECIMENTOS.NOVO_CONTATO") }}
      </b-button>
    </div>

    <div class="d-flex align-items-center mb-2">
      <b-button
        id="btn-editar-contato"
        @click="editar(null)"
        v-if="dadosSelecionados.length == 1 && permissao"
        class="mr-2"
        variant="outline-primary"
      >
        {{ $t("GERAL.EDITAR") }}
      </b-button>
      <b-button
        id="btn-alterar-situacao-contato"
        @click="ativarInativar(botaoAtivarInativar)"
        v-if="dadosSelecionados.length > 0 && permissao"
        variant="outline-primary"
      >
        {{ `${!botaoAtivarInativar ? $t("GERAL.ATIVAR") : $t("GERAL.INATIVAR")}` }}
      </b-button>
    </div>

    <valorem-tabela
      id="tabela-contatos"
      sem-selecionar-todos
      :metodo-validacao-selecionar="metodoValidacaoSelecionar"
      v-model="tabelaFiltrada"
      :colunas="tabela.colunas"
      ref="tabela"
    >
      <template #cell(ativo)="dados">
        <span :class="`text-${retornarVariante(dados.value).variante} text-capitalize`">
          {{ retornarVariante(dados.value).text }}
        </span>
      </template>
    </valorem-tabela>

    <b-row class="mt-3">
      <b-col cols="12" class="text-right mt-3">
        <b-button
          id="btn-cancelar-contato"
          class="mx-3"
          variant="outline-primary"
          @click="cancelar"
        >
          {{ $t("GERAL.CANCELAR") }}
        </b-button>
        <b-button id="btn-continuar-contato" variant="primary" @click="continuar">
          {{ $t("GERAL.CONTINUAR") }}
        </b-button>
      </b-col>
    </b-row>

    <modal-contato
      :modalData="dadosSelecionados"
      ref="modalContato"
      :form="formContato"
      @salvar="salvar"
      @cancelar="limparFormulario"
    />
  </b-card>
</template>

<script>
import ModalContato from "./components/ModalContato";
import InputText from "@/components/inputs/InputText";
import helpers from "@/common/utils/helpers";
import cloneDeep from "lodash.clonedeep";

export default {
  name: "ContaBancaria",
  props: {
    form: {
      required: true,
    },
  },
  components: {
    InputText,
    ModalContato,
  },
  data() {
    return {
      filtro: "",
      formContato: {
        nome: "",
        numeroTelefone: "",
        email: "",
        tipoContato: "",
      },
      formAuxiliar: null,
      tabela: {
        colunas: [
          { key: "seletor", label: "" },
          { key: "tipoContato", label: this.$t("ESTABELECIMENTOS.CONTATO.TIPO") },
          { key: "nome", label: this.$t("ESTABELECIMENTOS.CONTATO.NOME") },
          { key: "email", label: this.$t("ESTABELECIMENTOS.CONTATO.EMAIL") },
          {
            key: "numeroTelefone",
            label: this.$t("ESTABELECIMENTOS.CONTATO.CELULAR"),
            formatter: (v) => {
              if (v) return helpers.maskTelefone(v);
            },
          },
          { key: "ativo", label: this.$t("GERAL.STATUS") },
        ],
      },
      botaoAtivarInativar: null,
    };
  },
  computed: {
    quantidadeItens: function () {
      return this.tabelaFiltrada.length;
    },
    dadosSelecionados: function () {
      return this.tabelaFiltrada.filter((el) => {
        return el.selecionado;
      });
    },
    tabelaFiltrada: {
      set: function (dados) {
        this.form.contatos = dados;
      },
      get: function () {
        return this.form.contatos.filter((el) => {
          return (
            el.nome.includes(this.filtro) ||
            el.email.includes(this.filtro) ||
            el.numeroTelefone.includes(this.filtro)
          );
        });
      },
    },
    permissao: function () {
      return this.form.id
        ? helpers.validarAcesso("Estabelecimento").criar
        : helpers.validarAcesso("Estabelecimento").editar;
    },
  },

  methods: {
    verificarId() {
      return (
        this.dadosSelecionados.filter((el) => {
          return !el.id;
        }).length > 0
      );
    },
    continuar: function () {
      this.$emit("submitted");
    },
    cancelar: function () {
      this.$router.push({ name: "estabelecimentos" });
    },
    salvar: function (form) {
      this.formContato.nome = form.nome;
      this.formContato.numeroTelefone = form.numeroTelefone;
      this.formContato.email = form.email;
      this.formContato.tipoContato = form.tipoContato;

      let contato = this.formContato;
      contato.numeroTelefone = helpers.removerMascara(contato.numeroTelefone);

      if (!this.formContato.id && !this.formContato.codigo) {
        contato.codigo = Math.random();
        contato.ativo = true;
        this.form.contatos.push(contato);
      } else {
        this.form.contatos[this.formAuxiliar] = contato;
        this.$refs.tabela.atualizarValor(this.form.contatos);
      }
      this.$refs.modalContato.fecharModal();
    },
    limparFormulario: function () {
      this.formContato = {
        nome: "",
        numeroTelefone: "",
        email: "",
      };
    },
    editar: function () {
      this.formContato = cloneDeep(this.dadosSelecionados[0]);
      this.formAuxiliar = this.form.contatos.findIndex(
        (el) =>
          el.id == this.dadosSelecionados[0].id ||
          el.codigo == this.dadosSelecionados[0].codigo
      );
      this.$refs.modalContato.abrirModal();
    },
    metodoValidacaoSelecionar: function () {
      return true;
    },
    abrirModal() {
      this.$refs.modalContato.abrirModal();
    },
    ativarInativar: function (ativarInativar) {
      this.confirmar(
        this.$t(
          `ESTABELECIMENTOS.CONTATO.TITULO_${!ativarInativar ? "ATIVAR" : "INATIVAR"}`
        ),
        this.$t(
          `ESTABELECIMENTOS.CONTATO.CONFIRMACAO_${
            !ativarInativar ? "ATIVAR" : "INATIVAR"
          }`
        ),
        this.$t(
          `ESTABELECIMENTOS.CONTATO.BOTAO_${!ativarInativar ? "ATIVAR" : "INATIVAR"}`
        ),
        this.$t(`GERAL.CANCELAR`)
      ).then((confirmado) => {
        if (!confirmado) return;
        if (ativarInativar) {
          if (
            this.tabelaFiltrada.filter((el) => {
              return !el.selecionado && el.ativo;
            }).length == 0
          ) {
            this.toastErro("O estabelecimento deve ter pelo menos um contato ativo!");
            return;
          }
        }
        let ids = this.dadosSelecionados.map((el) => el.id || el.codigo);
        this.form.contatos = this.form.contatos.map((el) => {
          if (ids.includes(el.id || el.codigo)) el.ativo = !el.ativo;
          el.selecionado = false;
          return el;
        });
      });
    },
  },
  mounted() {},
  watch: {
    dadosSelecionados: function () {
      if (this.dadosSelecionados.length == 1) {
        this.botaoAtivarInativar = this.dadosSelecionados[0].ativo;
      } else if (this.dadosSelecionados.length == 0) {
        this.botaoAtivarInativar = null;
      }
    },
  },
};
</script>
