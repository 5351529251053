<template>
  <valorem-modal
    ref="modal"
    :titulo-modal="$t('ESTABELECIMENTOS.NOVO_TOKEN')"
    @cancelar="fecharModal"
    @ok="fecharModal"
    tamanho="md"
    okDisabilitado
    esconderRodape
  >
    <div class="row">
      <div class="col-12 col-md-12">
        {{ $t("ESTABELECIMENTOS.NOVO_TOKEN_GERADO") }}
      </div>

      <div class="col-12 col-md-12 mt-2">
        <div class="alert alert-secondary text-center" role="alert">
          {{ token }}
        </div>
      </div>
    </div>
  </valorem-modal>
</template>
  <script>
export default {
  name: "ModalToken",
  props: {
    token: {
      required: true,
    },
  },
  methods: {
    abrirModal: function () {
      this.$refs.modal.abrirModal();
    },
    fecharModal: function () {
      this.$refs.modal.fecharModal();
    },
  },
};
</script>
  