<template>
  <div>
    <b-card class="mb-4">
      <div class="row">
        <input-mask
          id="input-cep"
          class="col-12 col-md-2"
          :label="$t('ESTABELECIMENTOS.CEP')"
          v-model="form.endereco.cep"
          @change="consultaCep"
          :mask="['#####-###']"
          ref="cep"
          required
        />
        <input-text
          id="input-endereco"
          class="col-12 col-md-5"
          :label="$t('ESTABELECIMENTOS.ENDERECO')"
          v-model="form.endereco.logradouro"
          ref="logradouro"
          required
        />
        <input-text
          id="input-numero"
          class="col-12 col-md-2"
          :label="$t('ESTABELECIMENTOS.NUMERO')"
          v-model="form.endereco.numero"
          ref="numero"
          required
        />
        <input-text
          id="input-complemento"
          class="col-12 col-md-3"
          :label="$t('ESTABELECIMENTOS.COMPLEMENTO')"
          v-model="form.endereco.complemento"
          ref="complemento"
        />
        <input-text
          id="input-bairro"
          class="col-12 col-md-3"
          :label="$t('ESTABELECIMENTOS.BAIRRO')"
          v-model="form.endereco.bairro"
          ref="bairro"
          required
        />
        <input-text
          id="input-cidade"
          class="col-12 col-md-3"
          :label="$t('ESTABELECIMENTOS.CIDADE')"
          v-model="form.endereco.cidade"
          ref="cidade"
          required
        />
        <input-mask
          id="input-estado"
          class="col-12 col-md-3"
          :mask="['AA']"
          :label="$t('ESTABELECIMENTOS.ESTADO')"
          v-model="form.endereco.uf"
          :max="2"
          :min="2"
          ref="uf"
          required
        />
        <input-select
          id="input-pais"
          class="col-12 col-md-3"
          :label="$t('ESTABELECIMENTOS.PAIS')"
          :options="opcoes.paises"
          v-model="form.endereco.pais"
          ref="paises"
          required
        />
      </div>
      <b-row>
        <b-col cols="12" class="text-right mt-3">
          <b-button
            id="btn-cancelar-endereco"
            class="mx-3"
            variant="outline-primary"
            @click="cancelar"
            >{{ $t("GERAL.CANCELAR") }}</b-button
          >
          <b-button id="btn-continuar-endereco" variant="primary" @click="continuar">{{
            $t("GERAL.CONTINUAR")
          }}</b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { InputText, InputSelect, InputMask } from "@/components/inputs";
import helpers from "@/common/utils/helpers";
import cep from 'cep-promise'

export default {
  name: "Endereco",
  components: {
    InputText,
    InputSelect,
    InputMask,
  },
  props: {
    form: {
      required: true,
    },
  },
  data() {
    return {
      opcoes: {
        paises: [{ value: "Brasil", text: "Brasil" }],
      },
    };
  },
  methods: {
    cancelar: function () {
      this.$router.push({ name: "estabelecimentos" });
    },
    continuar: function () {
      this.confirmar(
        this.$t("GERAL.CONFIRMAR"),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        if (!this.validarFormulario()) return false;

        this.$emit("submitted");
      });
    },
    consultaCep: function () {
      const value = helpers.removerMascara(this.form.endereco.cep);

      var validacep = /^[0-9]{8}$/;
      if(validacep.test(value)){
        cep(value).then((res)=>{
          let endereco = {};
          endereco.cep = res.cep;
          endereco.logradouro = res.street;
          endereco.bairro = res.neighborhood;
          endereco.cidade = res.city;
          endereco.uf = res.state;
          endereco.pais = 'Brasil';
          this.form.endereco = endereco;
        });
      }
    },
  },
};
</script>
