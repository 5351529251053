<template>
  <b-card no-body class="mb-4 p-3">
    <div>
      <h4 class="card-title">{{ quantidadeItens }} registros</h4>
    </div>
    <div class="filtro-valorem d-flex align-items-center justify-content-between mb-2">
      <div>
        <b-button
          id="btn-alterar-situacao-usuario"
          @click="ativarInativar(botaoAtivarInativar)"
          v-if="dadosSelecionados.length > 0 && permissao"
          variant="outline-primary"
        >
          {{ `${!botaoAtivarInativar ? $t("GERAL.ATIVAR") : $t("GERAL.INATIVAR")}` }}
        </b-button>
      </div>
      <b-button
        id="btn-novo-usuario"
        @click="abrirModal"
        v-if="permissao"
        variant="primary"
      >
        {{ $t("ESTABELECIMENTOS.NOVO_USUARIO") }}
      </b-button>
    </div>

    <valorem-tabela
      id="tabela-usuarios"
      sem-selecionar-todos
      :metodo-validacao-selecionar="metodoValidacaoSelecionar"
      v-model="form.usuarios"
      :colunas="tabela.colunas"
      ref="tabela"
    >
      <template #cell(ativo)="dados">
        <span :class="`text-${retornarVariante(dados.value).variante} text-capitalize`">
          {{ retornarVariante(dados.value).text }}
        </span>
      </template>
    </valorem-tabela>

    <b-row class="mt-3">
      <b-col cols="12" class="text-right mt-3">
        <b-button
          id="btn-cancelar-usuario"
          class="mx-3"
          variant="outline-primary"
          @click="cancelar"
          >{{ $t("GERAL.CANCELAR") }}</b-button
        >
        <b-button id="btn-continuar-usuario" variant="primary" @click="continuar">{{
          $t("GERAL.CONTINUAR")
        }}</b-button>
      </b-col>
    </b-row>

    <modal-usuarios ref="modalUsuarios" :form="form.usuarios" @salvar="salvar" />
  </b-card>
</template>

<script>
import UsuarioService from "@/common/services/usuario/usuario.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import ModalUsuarios from "./components/ModalUsuarios";
import helpers from "@/common/utils/helpers";
import cloneDeep from "lodash.clonedeep";

export default {
  name: "Usuarios",
  props: {
    form: {
      required: true,
    },
  },
  components: {
    ModalUsuarios,
  },
  data() {
    return {
      tabela: {
        colunas: [
          { key: "seletor", label: "" },
          { key: "nome", label: this.$t("USUARIOS.TABELA.NOME"), sortable: true },
          {
            key: "cpf",
            label: this.$t("USUARIOS.TABELA.CPF"),
            formatter: (v) => {
              if (v) return helpers.maskCpf(v);
            },
            sortable: true,
          },
          { key: "email", label: this.$t("USUARIOS.TABELA.EMAIL"), sortable: true },
          {
            key: "numeroTelefone",
            label: this.$t("USUARIOS.TABELA.CONTATO_CELULAR"),
            formatter: (v) => {
              if (v) return helpers.maskTelefone(v);
            },
            sortable: true,
          },
          { key: "ativo", label: this.$t("USUARIOS.TABELA.SITUACAO"), sortable: true },
        ],
      },
      botaoAtivarInativar: null,
    };
  },
  computed: {
    quantidadeItens: function () {
      return this.form.usuarios.length;
    },
    dadosSelecionados: function () {
      return this.form.usuarios.filter((el) => {
        return el.selecionado;
      });
    },
    permissao: function () {
      return this.form.id
        ? helpers.validarAcesso("Estabelecimento").criar
        : helpers.validarAcesso("Estabelecimento").editar;
    },
  },

  methods: {
    continuar: function () {
      this.$emit("submitted");
    },
    cancelar: function () {
      this.$router.push({ name: "estabelecimentos" });
    },
    salvar: function (dados) {
      this.form.usuarios = cloneDeep(dados);
      this.toastSucesso(this.$t(`ESTABELECIMENTOS.USUARIOS.ADICIONADO`));
      this.$refs.modalUsuarios.fecharModal();
    },
    metodoValidacaoSelecionar: function (item) {
      if (this.botaoAtivarInativar == null) return true;
      if (item.ativo != this.botaoAtivarInativar) return false;
      return true;
    },
    abrirModal() {
      this.$refs.modalUsuarios.abrirModal();
    },
    retornarVariante: function (situacao) {
      return helpers.Situacao.find((el) => el.value == situacao);
    },
    ativarInativar: function (ativarInativar) {
      this.confirmar(
        this.$t(`USUARIOS.TITULO_${!ativarInativar ? "ATIVAR" : "INATIVAR"}`),
        this.$t(`USUARIOS.CONFIRMACAO_${!ativarInativar ? "ATIVAR" : "INATIVAR"}`),
        this.$t(`USUARIOS.BOTAO_${!ativarInativar ? "ATIVAR" : "INATIVAR"}`),
        this.$t(`GERAL.CANCELAR`)
      ).then((confirmado) => {
        if (!confirmado) return;
        this.$store.dispatch(START_LOADING);
        UsuarioService.ativarInativar(
          { usuariosId: this.dadosSelecionados.map((el) => el.id) },
          !ativarInativar
        )
          .then(() => {
            this.toastSucesso(
              this.$t(`USUARIOS.${!ativarInativar ? "ATIVAR" : "INATIVAR"}_SUCESSO`)
            );
            this.form.usuarios = this.dadosSelecionados.map((el) => {
              el.ativo = !ativarInativar;
              return el;
            });
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
  },
  mounted() {},
  watch: {
    dadosSelecionados: function () {
      if (this.dadosSelecionados.length == 1) {
        this.botaoAtivarInativar = this.dadosSelecionados[0].ativo;
      } else if (this.dadosSelecionados.length == 0) {
        this.botaoAtivarInativar = null;
      }
    },
  },
};
</script>
