<template>
  <div>
    <b-tabs v-model="tabIndex" content-class="tab-autenticacao" class="abas-formulario">
      <b-tab id="tab-ficha" title="Ficha cadastral">
        <Ficha ref="fichaRef" :form="form" @submitted="mudarAba" />
      </b-tab>
      <b-tab id="tab-contato" title="Contato" :disabled="!liberarEtapa2">
        <Contato ref="contatoRef" :form="form" @submitted="mudarAba" />
      </b-tab>
      <b-tab id="tab-endereco" title="Endereço" :disabled="!liberarEtapa2">
        <Endereco ref="enderecoRef" :form="form" @submitted="mudarAba" />
      </b-tab>
      <b-tab id="tab-conta" title="Conta bancária" :disabled="!liberarEtapa3">
        <ContaBancaria ref="contaBancariaRef" :form="form" @submitted="mudarAba" />
      </b-tab>
      <b-tab id="tab-usuarios" v-if="form.id" title="Usuários" :disabled="!liberarEtapa3">
        <Usuarios ref="usuariosRef" :form="form" @submitted="mudarAba" />
      </b-tab>
      <b-tab id="tab-antecipacao" title="Antecipação" :disabled="!liberarEtapa3">
        <Antecipacao ref="antecipacaoRef" :form="form" @salvar="salvar" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Ficha from "./Ficha.vue";
import Contato from "./Contato.vue";
import Endereco from "./Endereco.vue";
import ContaBancaria from "./ContaBancaria.vue";
import Usuarios from "./Usuarios.vue";
import Antecipacao from "./Antecipacao.vue";

import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import EstabelecimentoService from "@/common/services/estabelecimentos/estabelecimentos.service";
import cloneDeep from "lodash.clonedeep";
import helpers from "../../common/utils/helpers";
import { BUSCAR_ESTABELECIMENTO } from "@/store/app.module";

export default {
  name: "Formulario",
  props: ["id"],
  components: {
    Ficha,
    Contato,
    Endereco,
    ContaBancaria,
    Usuarios,
    Antecipacao,
  },
  data() {
    return {
      tabIndex: 0,
      form: {
        cpfCnpj: null,
        razaoSocial: null,
        nomeFantasia: null,
        cnaeMCC: null,
        MCC: null,
        email: null,
        softDescriptor: null,
        enviaLinkRecorrencia: true,
        liberarAntecipacaoRecorrencia: false,
        dataInicioAntecipacao: null,
        faturamentoMensal: 0,
        percentualAntecipacao: 0,
        limiteMaximoInicial: 0,
        validadeLimiteInicialDias: 0,
        taxaCreditoAVista: 0,
        limiteInadimplencia: 0,
        endereco: {
          cep: null,
          logradouro: null,
          numero: null,
          complemento: null,
          bairro: null,
          cidade: null,
          uf: null,
          pais: "Brasil",
        },
        contasBancarias: [],
        contatos: [],
        usuarios: [],
        usuarioIds: [],
        grupoEconomicoId: null,
        representanteComercialId: null,
        valorLimiteCreditoUtilizado: 0,
        valorLimiteCreditoDisponivel: 0,
      },
      liberarEtapa2: false,
      liberarEtapa3: false,
      alterado: false,
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("ESTABELECIMENTOS.TITULO_LISTA"), to: "/estabelecimentos" },
      {
        titulo: this.$t(
          `ESTABELECIMENTOS.TITULO_FORMULARIO_${!this.id ? "NOVO" : "EDICAO"}`
        ),
      },
    ]);
    if (this.id) {
      this.buscar();
    }
    this.validarAbas();
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "Login" && this.form.id && this.alterado) {
      this.confirmar(
        "Aviso",
        "Você possui alterações não salvas, deseja realmente sair?"
      ).then((confirmado) => {
        if (confirmado) next();
        next(false);
      });
    } else {
      next();
    }
  },
  methods: {
    validarAbas: function (escondido = true) {
      this.liberarEtapa2 = this.$refs.fichaRef.validarFormulario("", escondido);
      this.liberarEtapa3 =
        this.$refs.enderecoRef.validarFormulario("", escondido) && this.liberarEtapa2;
      this.liberarSalvar =
        this.$refs.antecipacaoRef.validarFormulario("", escondido) &&
        this.liberarEtapa2 &&
        this.liberarEtapa3;

      if (!this.liberarEtapa2) {
        this.tabIndex = 0;
        return false;
      }
      if (!this.liberarEtapa3 && this.tabIndex > 2) {
        this.tabIndex = 2;
        return false;
      }
      if (!this.liberarSalvar && this.tabIndex > 5) {
        this.tabIndex = 5;
        return false;
      }

      return true;
    },
    mudarAba: function () {
      if (this.validarAbas()) {
        setTimeout(() => {
          this.tabIndex++;
        }, 500);
      }
    },
    buscar: function () {
      this.$store.dispatch(START_LOADING);
      EstabelecimentoService.buscar(this.id)
        .then((res) => {
          this.form = res.data.data;
          this.form.faturamentoMensal = helpers.formatarValor(
            this.form.faturamentoMensal
          );
          this.form.limiteMaximoInicial = helpers.formatarValor(
            this.form.limiteMaximoInicial
          );
          this.form.limiteInadimplencia = helpers.formatarValor(
            this.form.limiteInadimplencia
          );

          this.form.contasBancarias = this.form.contasBancarias.map((el) => {
            el.selecionado = false;
            return el;
          });

          setTimeout(() => {
            this.$watch(
              `form`,
              function () {
                this.alterado = true;
              },
              { deep: true }
            );
          }, 500);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.validarAbas();
        });
    },
    salvar: function () {
      this.confirmar(
        this.$t(`ESTABELECIMENTOS.TITULO_SALVAR`),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        if (!this.validarAbas(false)) return false;

        let form = cloneDeep(this.form);
        form.usuarioIds = this.form.usuarios.map((el) => el.id);
        form.cpfCnpj = helpers.removerMascara(form.cpfCnpj);
        form.faturamentoMensal = helpers.removerValor(`${form.faturamentoMensal}`);
        form.limiteMaximoInicial = helpers.removerValor(`${form.limiteMaximoInicial}`);
        form.limiteInadimplencia = helpers.removerValor(`${form.limiteInadimplencia}`);
        form.endereco.cep = helpers.removerMascara(form.endereco.cep);

        this.$store.dispatch(START_LOADING);
        EstabelecimentoService.salvar(form)
          .then((res) => {
            this.$store.dispatch(BUSCAR_ESTABELECIMENTO);

            this.alertaSucesso(
              res.data.informations.length > 0
                ? res.data.informations
                : this.$t("ESTABELECIMENTOS.MENSAGEM_SUCESSO")
            ).then(() => {
              this.alterado = false;
              this.$router.push({ name: "estabelecimentos" });
            });
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
